import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box } from 'theme-ui'
import { SectionCtaBlock } from 'gatsby-theme-octahedroid'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Provider from '../components/provider'
import cta from '../cta'

const PageTemplate = ({ data: { mdx } }) => {
  return (
    <>
      <SEO
        {...{
          title: mdx.frontmatter.title,
          description: mdx.frontmatter.description,
          keywords: mdx.frontmatter.keywords,
          path: mdx.frontmatter.path,
        }}
      />
      <Layout>
        <Box __css={{ py: 'small' }}>
          <Provider>{mdx.body}</Provider>
        </Box>
        {mdx.frontmatter.cta && (
          <SectionCtaBlock {...cta[mdx.frontmatter.cta]} />
        )}
      </Layout>
    </>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      body: PropTypes.string,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        keywords: PropTypes.arrayOf(PropTypes.string),
        cta: PropTypes.string,
        path: PropTypes.string,
      }),
    }),
  }).isRequired,
}

export default PageTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
        source
      }
      body
      frontmatter {
        title
        description
        keywords
        cta
        path
      }
    }
  }
`
